import chevron from '@a/icons/input-chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexSpace } from './layout/styles/classes';

export const Form = styled.form<{ submit: boolean; sm?: boolean }>`
    padding: 32px 16px;
    position: relative;
    width: 100%;
    max-width: ${({ sm }) => (sm ? '630px' : '750px')};
    scroll-margin-top: calc(88px + (1rem * 1.5));
    margin: 0 auto;
    border-radius: 32px;
    background-color: ${({ theme }) => theme.colors.white};

    > h2 {
        margin: 0 0 32px;
        color: ${({ theme }) => theme.colors.blue};
    }

    @media (min-width: 768px) {
        border-radius: 64px;
        padding: 64px;
    }

    ${({ submit }) =>
        submit &&
        css`
            select,
            textarea,
            input,
            button {
                pointer-events: none;
                visibility: hidden;
            }
        `};
`;

export const Input = styled.input<{ rows?: number; setColor?: boolean }>`
    color: ${({ theme, setColor }) => (setColor ? '#737373' : theme.colors.blue)};
    border: 1px solid ${({ theme }) => theme.colors.offWhite};
    background-color: ${({ theme }) => theme.colors.offWhite};
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
    display: block;
    outline: none;
    transition: border-color 0.3s ease-in;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 150%;
    resize: none;

    :hover {
        border-color: ${({ theme }) => theme.colors.blue};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.blue};
    }

    ::placeholder {
        color: '#737373';
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.blue};
        -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.offWhite} inset;
        transition: background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #e75842;
    }

    ${({ setColor, theme }) =>
        setColor &&
        css`
            background-image: url(${chevron});
            background-position: center right 20px;
            background-repeat: no-repeat;
            appearance: none;
            text-transform: capitalize;

            &::-ms-expand {
                display: none;
            }

            :focus,
            :focus-within {
                color: ${theme.colors.blue};
            }
        `};
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 16px;

    > span {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 4px;
        line-height: 182%;
    }

    :hover,
    :focus-within {
        > span {
            color: ${({ theme }) => theme.colors.blue};
        }
    }

    :focus-within:invalid {
        > span {
            color: red;
        }
    }
`;

export const InputFlex = styled.div`
    @media (min-width: 550px) {
        ${flexSpace};

        > label {
            width: calc(50% - 16px);

            :first-of-type {
                width: 50%;
                margin-right: 16px;
            }
        }
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: inherit;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.white};

    > h2 {
        margin-top: 0;
        color: ${({ theme }) => theme.colors.blue};
    }

    > p {
        margin-bottom: 0;
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.darkBlue};
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;

export const Thanks = ({ submit }: { submit: boolean }) => (
    <StyledThanks submit={submit}>
        <h2>Thank you</h2>
        <p>Someone will be right with you shortly!</p>
    </StyledThanks>
);
