import { PlainBtn } from '@/buttons';
import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { ReactComponent as ChevronIcon } from '@a/icons/chevron.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { useState } from 'react';
import { getNavLink, siteInfo } from './NavLinks';
import { flexCenter } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const Open = styled(PlainBtn)`
    ${flexCenter};
    background-color: ${({ theme }) => theme.colors.green};
    padding: 12px;
    transition: color 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;

    > svg {
        margin-left: 16px;
        width: 16px;
        height: 16px;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.blue};

        > svg > path {
            fill: ${({ theme }) => theme.colors.blue};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.blue};

        > svg > path {
            fill: ${({ theme }) => theme.colors.blue};
        }
    }
`;

const overlayStyles = css`
    background-color: rgba(155, 203, 98, 0.4);
`;

const contentStyles = css`
    border-top: 2px solid ${theme.colors.green};
    box-shadow: 0px 16px 32px -16px rgba(56, 65, 96, 0.2);
    border-radius: 0px 0px 64px 64px;
    padding: 32px 16px 40px;
    background-color: ${theme.colors.offWhite};
    width: 100%;
    top: 0;
    left: 0;
    max-height: 100%;

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-100%);
        }
    }

    @media (min-width: 1023px) {
        padding-left: 32px;
        padding-right: 32px;
    }
`;

const Boxes = styled.div<{ height: number }>`
    display: grid;
    grid-template-columns: repeat(auto-fit, 270px);
    gap: 32px 8.33vw; //120px at 1440px
    justify-content: center;
    max-height: ${({ height }) => `calc(${height}px - 74px)`};
    overflow-y: auto;

    > div {
        > p {
            margin: 0 0 16px;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 146%;
            color: ${({ theme }) => theme.colors.darkBlue};
            border-bottom: 4px solid ${({ theme }) => theme.colors.green};
        }

        > a {
            ${flexCenter};
            justify-content: flex-start;
            transition: color 0.3s ease-in-out;
            color: ${({ theme }) => theme.colors.darkBlue};
            margin-bottom: 16px;

            :last-of-type {
                margin-bottom: 0;
                text-decoration-line: underline;
            }

            > svg {
                margin-right: 8px;
                flex-shrink: 0;

                > path {
                    transition: fill 0.3s ease-in-out;
                }
            }

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.blue};

                > svg > path {
                    fill: ${({ theme }) => theme.colors.blue};
                }
            }
        }
    }

    @media (min-width: 1024px) and (max-width: 1599px) {
        gap: 32px;
        justify-content: space-between;
    }
`;

const Close = styled(PlainBtn)`
    ${flexCenter};
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: background-color 0.3s ease-in-out;
    width: max-content;
    box-shadow: 0px -16px 32px -4px rgba(56, 65, 96, 0.2);
    border-radius: 8px 8px 0px 0px;
    transition: color 0.3s ease-in-out;
    padding: 4px 8px;
    background-color: ${({ theme }) => theme.colors.white};

    > svg {
        margin-left: 11px;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.blue};

        > svg > path {
            fill: ${({ theme }) => theme.colors.blue};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.blue};

        > svg > path {
            fill: ${({ theme }) => theme.colors.blue};
        }
    }
`;

export const AllLocations = ({ className }: { className?: string }) => {
    const { height } = useWindowSize();

    return (
        <Boxes className={className} height={height}>
            {siteInfo.allLocations.map((info, i) => (
                <div key={i}>
                    <p>{info.name}</p>
                    <CLink to={info.address.link}>
                        <PinIcon /> {info.address.text}
                    </CLink>
                    <CLink to={info.phone.link}>
                        <PhoneIcon /> {info.phone.text}
                    </CLink>
                    <CLink to={getNavLink(info.name)}>More About {info.name}</CLink>
                </div>
            ))}
        </Boxes>
    );
};

export const LocationsBar = () => {
    const [open, setOpen] = useState(false);

    useRouteChange(setOpen);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                <Open>
                    View Locations <PinIcon />
                </Open>
            </DialogOpen>
            <DialogContent contentStyles={contentStyles} overlayStyles={overlayStyles}>
                <AllLocations />
                <DialogClose>
                    <Close>
                        Close <ChevronIcon />
                    </Close>
                </DialogClose>
            </DialogContent>
        </Dialog>
    );
};
