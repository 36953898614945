import { AppointmentForm } from '@/AppointmentForm';
import { PrimaryBtn } from '@/buttons';
import { CLink } from '@/CLink';
import { ContactForm } from '@/ContactForm';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { StaticImage } from 'gatsby-plugin-image';
import { siteInfo } from './NavLinks';
import { flexCenter, px, py } from './styles/classes';
import { h1 } from './styles/Typography';

const Section = styled.section`
    ${py};
    ${px};
    position: relative;

    > .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(31, 67, 105, 0.8);
            z-index: 1;
        }
    }

    > form {
        z-index: 2;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        padding-top: 72px;
        padding-bottom: 72px;

        > form {
            margin: 0;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    @media (min-width: 1440px) {
        padding-top: 128px;
        padding-bottom: 128px;
    }
`;

const TextBox = styled.div`
    max-width: 480px;
    z-index: 2;
    position: relative;
    margin: 0 auto 32px;

    > h2 {
        ${h1};
        color: ${({ theme }) => theme.colors.orange};
        margin: 0;
    }

    > p {
        max-width: 380px;
        color: ${({ theme }) => theme.colors.white};
        margin: 32px 0;
    }

    > a {
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;

        > svg > path {
            fill: ${({ theme }) => theme.colors.white};
            transition: fill 0.3s ease-in-out;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.green};

            > svg > path {
                fill: ${({ theme }) => theme.colors.green};
            }
        }
    }

    @media (min-width: 1024px) {
        order: 1;
        margin: 0 0 0 3.3333vw; //48px at 1440p
    }
`;

export const Appointment = () => {
    const { title } = useLocationData();

    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/appointment.jpg"
                alt="girl in hoodie"
                layout="fullWidth"
                quality={70}
            />
            <TextBox>
                <h2>{title === 'contact us' ? 'Contact Us' : 'Ready to Get Started?'}</h2>
                <p>
                    Fill out the form or call us to get started on your personalized treatment plan.
                </p>
                <PrimaryBtn green as={CLink} to={siteInfo.phone.link}>
                    <PhoneIcon />
                    {siteInfo.phone.text}
                </PrimaryBtn>
            </TextBox>
            {title === 'contact us' ? <ContactForm /> : <AppointmentForm />}
        </Section>
    );
};
