import { plainBtnStyles } from '@/buttons/PlainBtn';
import { css } from '@emotion/react';
import { flexSpace } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const hoverStyles = css`
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};

    > svg path {
        fill: ${theme.colors.white};
    }
`;

export const navLinkStyles = css`
    ${flexSpace};
    text-transform: uppercase;
    color: ${theme.colors.dark};
    padding: 12px 16px;
    position: relative;
    font-weight: 500;
    line-height: 150%;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    margin-bottom: 32px;

    &[aria-current='page'] {
        box-shadow: inset 0 -2px 0 ${theme.colors.blue};
    }

    :hover {
        ${hoverStyles};

        &[aria-current='page'] {
            box-shadow: inset 0 -2px 0 ${theme.colors.green};
        }
    }

    :focus-visible {
        ${hoverStyles};
    }

    @media (min-height: 720px) {
        margin-bottom: 40px;
    }

    @media (min-height: 760px) {
        margin-bottom: 48px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0 !important;
    }
`;

export const subNavLinkStyles = css`
    padding: 0;
    color: ${theme.colors.darkBlue};
    text-transform: capitalize;
    transition: color 0.3s ease-in-out;

    &[aria-current='page'] {
        background-color: transparent;
        color: ${theme.colors.blue};
    }

    :hover {
        background-color: transparent;
        color: ${theme.colors.green};
    }

    :focus-visible {
        background-color: transparent;
        color: ${theme.colors.green};
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};
    z-index: 20;
    margin: 0 auto 40px;

    > svg {
        margin-left: 8px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    @media (min-height: 720px) {
        margin-bottom: 48px;
    }

    @media (min-height: 760px) {
        margin-bottom: 80px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0 !important;
    }

    &[data-mobile-expanded] {
        ${hoverStyles};
        background-color: ${theme.colors.blue};

        > svg {
            transform: rotate(180deg);
        }
    }

    &.current-nav-category {
        > svg path {
            fill: ${theme.colors.blue};
        }
    }

    &[aria-expanded='true'] {
        ${hoverStyles};
        > svg {
            transform: rotate(180deg);
        }
    }
`;
