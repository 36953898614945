import { ReactComponent as ChevronIcon } from '@a/icons/navlink-chevron.svg';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from '@mdx-js/react/lib';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import useLocationData from '@s/hooks/useLocationData';
import { Fragment } from 'react';
import { CLink } from './CLink';
import { NavLinksTypes } from './layout/NavLinks';
import { flexCenter } from './layout/styles/classes';

const Nav = styled.nav`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        width: 100%;
    }
`;

const Content = styled(NavigationMenu.Content)<{ multi: boolean }>`
    position: fixed;
    top: calc((24px + (1rem * 1.5)) + 64px);
    left: 0;
    width: 100%;
    animation-duration: 250ms;
    animation-timing-function: ease;

    > ul {
        list-style-type: none;
        padding-inline-start: 0;
        margin: 0;
        max-width: 1440px;

        ${({ multi }) =>
            multi &&
            css`
                display: flex;
                justify-content: space-between;
                width: 100%;
            `};
    }

    &[data-state='open'],
    &[data-motion='from-start'] {
        animation-name: enterFromTop;
    }

    &[data-motion='from-end'] {
        animation-name: enterFromTop;
    }

    &[data-state='closed'],
    &[data-motion='to-start'] {
        animation-name: exitToTop;
    }
    &[data-motion='to-end'] {
        animation-name: exitToTop;
    }

    @keyframes enterFromTop {
        from {
            opacity: 0;
            transform: translateY(-200px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes exitToTop {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-200px);
        }
    }
`;

type MenuProps = {
    children: ReactNode;
    menuStyles?: SerializedStyles;
    contentStyles: SerializedStyles;
    navBtnStyles: SerializedStyles;
    navLinkStyles: SerializedStyles;
    subNavLinkStyles: SerializedStyles;
    links: NavLinksTypes;
    index?: number;
    className?: string;
};

export const DesktopNav = ({
    children,
    navBtnStyles,
    menuStyles,
    contentStyles,
    subNavLinkStyles,
    navLinkStyles,
    links,
    index,
    className,
}: MenuProps) => {
    const { title, category } = useLocationData();

    return (
        <Nav className={className}>
            <NavigationMenu.Root asChild>
                <NavigationMenu.List
                    css={css`
                        ${flexCenter};
                        ${menuStyles};
                        list-style-type: none;
                        padding-inline-start: 0;
                        margin: 0;
                    `}
                >
                    {links.slice(0, index).map((data, i) =>
                        data.links ? (
                            <NavigationMenu.Item key={i}>
                                <NavigationMenu.Trigger
                                    css={navBtnStyles}
                                    className={category === data.text ? 'current-nav-category' : ''}
                                >
                                    {data.text}
                                    <ChevronIcon />
                                </NavigationMenu.Trigger>
                                <Content css={contentStyles} multi={data.text === 'procedures'}>
                                    <ul>
                                        {data.links?.map((subData, i) =>
                                            subData.links ? (
                                                <div key={i}>
                                                    <p>{subData.text}</p>
                                                    {subData.links?.map((data, i) => (
                                                        <li key={i}>
                                                            <NavigationMenu.Link
                                                                asChild
                                                                active={data.text === title}
                                                            >
                                                                <CLink
                                                                    to={data.link}
                                                                    css={subNavLinkStyles}
                                                                >
                                                                    {data.text}
                                                                </CLink>
                                                            </NavigationMenu.Link>
                                                        </li>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Fragment key={i}>
                                                    {i === 0 ? <p>{data.text}</p> : ''}
                                                    <li>
                                                        <NavigationMenu.Link
                                                            asChild
                                                            active={subData.text === title}
                                                        >
                                                            <CLink
                                                                to={subData.link}
                                                                css={subNavLinkStyles}
                                                            >
                                                                {subData.text}
                                                            </CLink>
                                                        </NavigationMenu.Link>
                                                    </li>
                                                </Fragment>
                                            )
                                        )}
                                    </ul>
                                </Content>
                            </NavigationMenu.Item>
                        ) : (
                            <NavigationMenu.Item key={i}>
                                <NavigationMenu.Link asChild active={data.text === title}>
                                    <CLink to={data.link} css={navLinkStyles}>
                                        {data.text}
                                    </CLink>
                                </NavigationMenu.Link>
                            </NavigationMenu.Item>
                        )
                    )}
                </NavigationMenu.List>
            </NavigationMenu.Root>
            {children}
        </Nav>
    );
};
