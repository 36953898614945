import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Form, Input, Label, Thanks } from './FormComponents';
import { PrimaryBtn } from './buttons';

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit(true);

        // const data = Object.fromEntries(new FormData(e.currentTarget));
        // await fetch(`https://madebyarif.com/test/form_submission.php`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // });

        emailjs.sendForm('service_9469dtj', 'template_6or43lm', e.target, 'j9NnOWrS4jKBEt1LZ').then(
            result => {
                console.log(result.text);
            },
            error => {
                console.log(error.text);
            }
        );
    };

    return (
        <Form onSubmit={handleSubmit} submit={submit} sm>
            <h2>We can’t wait to meet you</h2>

            <Label htmlFor="name">
                <span>Name</span>
                <Input type="text" id="name" name="name" placeholder="e.g. John Doe" />
            </Label>

            <Label htmlFor="email">
                <span>
                    Email address<strong>*</strong>
                </span>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="e.g: johndoe@gmail.com"
                />
            </Label>
            <Label htmlFor="phone">
                <span>Phone *</span>
                <PatternFormat
                    type="tel"
                    format="+1 (###) ###-####"
                    customInput={Input}
                    placeholder="123 456-7890"
                    id="phone"
                    name="phone"
                    required
                />
            </Label>

            <Label htmlFor="message">
                <span>A Message for Us</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={8}
                    placeholder="Enter your message..."
                />
            </Label>
            <PrimaryBtn big>Send us a Message</PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
};
