import { CLink } from '@/CLink';
import { ReactComponent as MailIcon } from '@a/icons/email.svg';
import { ReactComponent as FbIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstaIcon } from '@a/icons/instagram.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
// import mdmLogo from '@a/images/layout/mdm-logo.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { AllLocations } from './LocationsBar';
import { navLinks, siteInfo } from './NavLinks';
import { flexSpace, px, py } from './styles/classes';

const Section = styled.footer`
    ${px};
    ${py};
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};

    h2 {
        color: ${({ theme }) => theme.colors.orange};
        margin: 0 0 32px;
    }

    > .gatsby-image-wrapper {
        margin: 0 auto 64px;
    }

    @media (min-width: 1024px) {
        > h2 {
            text-align: center;
        }
    }
`;

const Locations = styled(AllLocations)`
    max-height: unset;
    overflow-y: unset;
    justify-content: start;

    > div {
        > p {
            color: ${({ theme }) => theme.colors.white};
            border-image-source: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 53.13%,
                rgba(255, 255, 255, 0) 100%
            );
            border-bottom: 1px solid;
            border-image-slice: 1;
            max-width: max-content;
        }

        > a {
            color: ${({ theme }) => theme.colors.white};

            > svg > path {
                fill: ${({ theme }) => theme.colors.white};
            }

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green};

                > svg > path {
                    fill: ${({ theme }) => theme.colors.green};
                }
            }
        }
    }

    @media (min-width: 1600px) {
        justify-content: space-between;
        max-width: 1775px;
        margin: 0 auto;
    }
`;

const Flex = styled.div`
    margin: 64px auto;
    max-width: 1775px;

    > div {
        :not(:last-of-type) {
            margin-bottom: 64px;
        }

        > a {
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme.colors.white};
            transition: color 0.3s ease-in-out;
            text-transform: capitalize;

            :not(:last-of-type) {
                margin-bottom: 16px;
            }

            > svg {
                margin-right: 16px;

                > path {
                    fill: ${({ theme }) => theme.colors.white};
                    transition: fill 0.3s ease-in-out;
                }
            }

            :first-of-type {
                > svg {
                    margin-right: 9px;
                }
            }

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green};

                > svg > path {
                    fill: ${({ theme }) => theme.colors.green};
                }
            }
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > a {
                margin-right: 64px;

                > svg > path {
                    fill: ${({ theme }) => theme.colors.white};
                    transition: fill 0.3s ease-in-out;
                }

                :hover,
                :focus {
                    > svg > path {
                        fill: ${({ theme }) => theme.colors.green};
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        ${flexSpace};
        align-items: flex-start;

        > div:not(:last-of-type) {
            margin-bottom: 0;
        }
    }
`;

// const Power = styled.p`
//     ${flexCenter};
//     flex-wrap: wrap;
//     font-size: 0.75rem;
//     text-transform: uppercase;
//     letter-spacing: 0.25em;
//     margin: 0;
//     color: ${({ theme }) => theme.colors.offWhite};

//     > img {
//         margin-right: 32px;
//     }

//     > a {
//         transition: color 0.3s ease-in-out;
//         color: ${({ theme }) => theme.colors.offWhite};
//         margin-left: 1ch;

//         :hover,
//         :focus {
//             color: ${({ theme }) => theme.colors.green};
//         }
//     }
// `;

export const Footer = () => {
    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/footer-logo.png"
                alt="tooth"
                width={70}
                height={65}
                layout="fixed"
            />
            <h2>COME SAY HI!</h2>
            <Locations />
            <Flex>
                <div>
                    <h2>QUESTIONS?</h2>
                    <CLink to={siteInfo.phone.link}>
                        <PhoneIcon />
                        {siteInfo.phone.text}
                    </CLink>
                    <CLink to={siteInfo.email.link}>
                        <MailIcon />
                        {siteInfo.email.text}
                    </CLink>
                </div>
                <div>
                    <h2>NAVIGATE</h2>
                    {navLinks
                        .filter(data => data.text === 'about us')[0]
                        ?.links?.map((data, i) => (
                            <CLink key={i} to={data.link}>
                                {data.text}
                            </CLink>
                        ))}
                </div>
                <div>
                    <h2>FOLLOW US!</h2>
                    <div>
                        <CLink to={siteInfo.social.facebook}>
                            <FbIcon width={24} height={24} />
                        </CLink>
                        <CLink to={siteInfo.social.instagram}>
                            <InstaIcon width={22} height={22} />
                        </CLink>
                    </div>
                </div>
            </Flex>
        </Section>
    );
};
