import vector from '@a/icons/btn-vector.png';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import PlainBtn from './PlainBtn';

const Btn = styled(PlainBtn)<{ big?: boolean; green?: boolean }>`
    padding: ${({ big }) => (big ? '16px' : '8px 16px')};
    background-color: ${({ green, theme }) => (green ? theme.colors.green : theme.colors.orange)};
    color: ${({ theme }) => theme.colors.white};
    text-transform: capitalize;
    border-radius: 32px;
    transition: background-color 0.3s ease-in-out;

    > img {
        margin-left: 16px;
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.blue};
    }

    :focus-visible {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: ElementType;
    children: ReactNode;
    big?: boolean;
    green?: boolean;
    to?: string;
};

export default function PrimaryBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            {children} <img src={vector} alt="" width={35} height={33} />
        </Btn>
    );
}
