import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { PatternFormat } from 'react-number-format';
import { PrimaryBtn } from './buttons';
import { Form, Input, InputFlex, Label, Thanks } from './FormComponents';

export const AppointmentForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit(true);

        // const data = Object.fromEntries(new FormData(e.currentTarget));
        // await fetch(`https://api.${window.location.host}/contact`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // });

        emailjs.sendForm('service_9469dtj', 'template_2za641h', e.target, 'j9NnOWrS4jKBEt1LZ').then(
            result => {
                console.log(result.text);
            },
            error => {
                console.log(error.text);
            }
        );
    };

    return (
        <Form onSubmit={handleSubmit} submit={submit} id="appointment">
            <h2>Book an Appointment</h2>

            <InputFlex>
                <Label htmlFor="name">
                    <span>Name</span>
                    <Input type="text" id="name" name="name" placeholder="e.g. John Doe" />
                </Label>

                <Label htmlFor="email">
                    <span>
                        Email address<strong>*</strong>
                    </span>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="e.g: johndoe@gmail.com"
                    />
                </Label>
            </InputFlex>
            <InputFlex>
                <Label htmlFor="phone">
                    <span>Phone *</span>
                    <PatternFormat
                        type="tel"
                        format="+1 (###) ###-####"
                        customInput={Input}
                        placeholder="123 456-7890"
                        id="phone"
                        name="phone"
                        required
                    />
                </Label>
                <Label htmlFor="time">
                    <span>Preferred Time</span>
                    <Input as="select" name="time" id="time" defaultValue="a" setColor>
                        <option value="a" disabled hidden>
                            Morning
                        </option>
                        <option value="Morning">morning</option>
                        <option value="Afternoon">afternoon</option>
                        <option value="Evening">evening</option>
                    </Input>
                </Label>
            </InputFlex>

            <Label htmlFor="location">
                <span>Preferred Location</span>
                <Input as="select" name="location" id="location" defaultValue="a" setColor>
                    <option value="a" disabled hidden>
                        Select a Location
                    </option>
                    <option value="Weston">weston</option>
                    <option value="Pembroke Pines">pembroke pines</option>
                    <option value="Kendall">kendall</option>
                    <option value="Coral Springs">coral springs</option>
                </Input>
            </Label>
            <Label htmlFor="message">
                <span>A Message for Us</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={8}
                    placeholder="Enter your message..."
                />
            </Label>
            <PrimaryBtn big>Book an Appointment</PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
};
