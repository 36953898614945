import { PrimaryBtn } from '@/buttons';
import { CLink } from '@/CLink';
import { DesktopNav as DesktopNavMenu } from '@/DesktopNavPrimitive';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { MobNav, scrollToAppointmentForm } from './MobNav';
import { navLinks, siteInfo } from './NavLinks';
import { navBtnStyles, navLinkStyles, subNavLinkStyles } from './NavLinkStyles';
import { flexCenter, flexSpace } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const Navbar = styled.header`
    ${flexSpace};
    padding-right: 16px;
    position: fixed;
    top: calc(24px + (1rem * 1.5));
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    border-bottom: 1px solid transparent;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 16px 32px -16px rgba(56, 65, 96, 0.2);
    z-index: 100;

    @media (min-width: 1921px) {
        padding: 0 calc((100vw - 1920px) / 2);
    }
`;

const HomeLink = styled(CLink)`
    display: flex;
    flex-shrink: 0;

    > .gatsby-image-wrapper:last-of-type {
        transition: opacity 0.3s ease-in-out;

        :hover {
            opacity: 0.7;
        }

        :focus-visible {
            opacity: 0.7;
        }
    }
`;

const DesktopNav = styled(DesktopNavMenu)`
    > div {
        margin: 0 auto;
    }
`;

const CallBtn = styled(PrimaryBtn)`
    color: ${({ theme }) => theme.colors.blue};
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: transparent;
    margin-bottom: 16px;

    > svg {
        margin-right: 12px;

        path {
            fill: ${({ theme }) => theme.colors.blue};
            transition: fill 0.3s ease-in-out;
        }
    }

    > img {
        display: none;
    }

    :hover {
        color: ${({ theme }) => theme.colors.white};

        > svg > path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.white};

        > svg > path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
    }
`;

export const NavCallBtn = () => (
    <CallBtn to={siteInfo.phone.link} as={CLink}>
        <PhoneIcon /> {siteInfo.phone.text}
    </CallBtn>
);

const BookBtn = styled(PrimaryBtn)`
    display: none;
    margin-left: 16px;

    @media (min-width: 1280px) {
        display: flex;

        > img {
            display: none;
        }
    }

    @media (min-width: 1310px) {
        > img {
            display: block;
        }
    }
`;

const contentStyles = css`
    ${flexCenter};
    background: #f2f2f2;
    box-shadow: 0px 16px 32px -16px rgba(56, 65, 96, 0.2), 0 0 0 500vmax rgba(155, 203, 98, 0.4);
    border-radius: 0px 0px 64px 64px;

    > ul {
        padding: 32px 64px;

        p {
            margin: 0 0 16px;
            line-height: 182%;
            border-bottom: 4px solid ${theme.colors.green};
            max-width: max-content;
            text-transform: uppercase;
            font-weight: 700;
            color: ${theme.colors.darkBlue};
        }

        li {
            :not(:last-of-type) {
                margin-bottom: 16px;
            }
        }
    }

    @media (min-width: 1280px) {
        > ul {
            padding: 48px 96px;
        }
    }
`;

export const Nav = () => {
    const { title } = useLocationData();

    return (
        <Fragment>
            <Navbar>
                <HomeLink to="/">
                    <StaticImage
                        src="../../../assets/images/layout/apple.png"
                        width={53}
                        height={64}
                        loading="eager"
                        alt="smiling girl holding apple"
                    />
                    <StaticImage
                        src="../../../assets/images/layout/logo.png"
                        width={179}
                        height={64}
                        loading="eager"
                        alt="gentle teeth braces"
                    />
                </HomeLink>

                <DesktopNav
                    navBtnStyles={navBtnStyles}
                    navLinkStyles={navLinkStyles}
                    subNavLinkStyles={subNavLinkStyles}
                    contentStyles={contentStyles}
                    links={navLinks}
                    index={4}
                >
                    <NavCallBtn />
                    <BookBtn
                        onClick={() =>
                            title === 'contact us'
                                ? navigate('/', {
                                      state: {
                                          scrollToForm: true,
                                      },
                                  })
                                : scrollToAppointmentForm()
                        }
                    >
                        book an appointment
                    </BookBtn>
                </DesktopNav>

                <MobNav />
            </Navbar>
        </Fragment>
    );
};
