import { css } from '@emotion/react';

export const h1 = css`
    font-size: 2.5rem;
    line-height: 120%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    font-size: 1.75rem;
    line-height: 162%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const h3 = css`
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const h4 = css`
    font-size: 1.125rem;
    line-height: 150%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;
`;

export const lgTxt = css`
    font-size: 1.125rem;
`;

export const orangeBorder = css`
    padding-left: 16px;
    border-left: 8px solid #f29617;
`;

export const greenBorder = css`
    padding-left: 16px;
    border-left: 8px solid #94c858;
`;

export const blueBorder = css`
    padding-left: 16px;
    border-left: 8px solid #1a5db8;
`;
