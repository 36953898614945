import { DefaultLayout } from '@s/layouts/DefaultLayout';
import { GatsbyBrowser } from 'gatsby';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
    <DefaultLayout {...props}>{element}</DefaultLayout>
);

export const onInitialClientRender = () => {
    const AMP_KEY = 'px1TC8c45OqKK2lIhIYUyagY67MSJGa';
    window._AMP_CONF = window._AMP_CONF || {};
    window._AMP_CONF.key = AMP_KEY;
    const s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.simplifeye.co/px1TC8c45OqKK2lIhIYUyagY67MSJGa/embed';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
};
