export const siteInfo = {
    allLocations: [
        {
            name: 'Weston',
            address: {
                text: '2751 Executive Park Dr. #204 Weston, FL 33331',
                link: 'https://goo.gl/maps/YogCh5x1BmrCKEZd7',
            },
            phone: {
                text: 'Call or Text (954) 217-0288',
                link: 'tel:19542170288',
            },
        },
        {
            name: 'Pembroke Pines',
            address: {
                text: '12251 Taft St #301 Pembroke Pines, FL 33026',
                link: 'https://goo.gl/maps/fku8yGcWZT4FxJBa7',
            },
            phone: {
                text: 'Call or Text (954) 437-7077',
                link: 'tel:+19544377077',
            },
        },
        {
            name: 'Kendall',
            address: {
                text: '6670 SW 117th Ave Miami, FL 33183',
                link: 'https://goo.gl/maps/NsfPF8Jz2hdVwko76',
            },
            phone: {
                text: 'Call or Text (305) 595-3400',
                link: 'tel:+13055953400',
            },
        },
        {
            name: 'Coral Springs',
            address: {
                text: '5401 N University Dr Suite 101 Coral Springs, FL 33067',
                link: 'https://goo.gl/maps/3zYzKNBo5VtsXPep6',
            },
            phone: {
                text: 'Call or Text (954) 344-3932',
                link: 'tel:+19453443932',
            },
        },
        {
            name: 'Cooper City',
            address: {
                text: '5900 N Hiatus Rd Suite 100 Cooper City, FL 33026',
                link: '...',
            },
            phone: {
                text: '',
                link: '',
            },
        },
    ],
    phone: {
        text: 'Call or Text (954) 217-0288',
        link: 'tel:+19542170288',
    },
    email: {
        link: 'mailto:info@gentleteeth.com',
        text: 'info@gentleteeth.com',
    },

    social: {
        facebook: 'https://www.facebook.com/GentleTeethDental/',
        instagram: 'https://www.instagram.com/gentleteethandbraces/',
        google: 'https://www.google.com/search?q=Gentle+Teeth+%26+Braces+Of+Weston+-+Dentist+in+Weston#lrd=0x88d9a1902e9c0d45:0xd8262f623b76717b,1,,,',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'locations',
        link: '/locations/',
        links: [
            {
                text: 'weston',
                link: '/weston-dentist/',
            },
            {
                text: 'pembroke pines',
                link: '/pembroke-pines/',
            },
            {
                text: 'kendall',
                link: '/kendall-fl-dentist/',
            },
            {
                text: 'coral springs',
                link: '/coral-springs-dentist/',
            },
            {
                text: 'cooper city',
                link: '/cooper-city-dentist/',
            },
        ],
    },
    {
        text: 'procedures',
        link: '/procedures/',
        links: [
            {
                text: 'orthodontics',
                link: '',
                links: [
                    {
                        text: 'invisalign',
                        link: '/invisalign/',
                    },
                    {
                        text: 'clear braces',
                        link: '/clear-braces/',
                    },
                ],
            },
            {
                text: 'cosmetic dentistry',
                link: '',
                links: [
                    {
                        text: 'bleaching',
                        link: '/bleaching/',
                    },
                    {
                        text: 'clear braces',
                        link: '/clear-braces/',
                    },
                    {
                        text: 'dental veneers',
                        link: '/dental-veneers/',
                    },
                    {
                        text: 'porcelain crowns',
                        link: '/porcelain-crowns/',
                    },
                    {
                        text: 'CEREC crowns',
                        link: '/cerec-crowns/',
                    },
                    {
                        text: 'white fillings',
                        link: '/white-fillings/',
                    },
                ],
            },
            {
                text: 'reconstructive dentistry',
                link: '',
                links: [
                    {
                        text: 'Dental Implants',
                        link: '/dental-implants/',
                    },
                    {
                        text: 'periodontal therapy',
                        link: '/perio-therapy/',
                    },
                    {
                        text: 'porcelain bridges',
                        link: '/porcelain-bridges/',
                    },
                    {
                        text: 'root canals',
                        link: '/root-canals/',
                    },
                    {
                        text: 'teeth extractions',
                        link: '/teeth-extractions/',
                    },
                    {
                        text: 'dental sealants',
                        link: '/dental-sealants/',
                    },
                    {
                        text: 'scaling and root planing',
                        link: '/scaling-and-root-planing/',
                    },
                ],
            },
        ],
    },
    {
        text: 'about us',
        link: '',
        links: [
            {
                text: 'meet us',
                link: '/meet-us/',
            },
            {
                text: 'smile gallery',
                link: '/smile-gallery/',
            },
            {
                text: 'community involvement',
                link: '/community-involvement/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
        ],
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },  
    {
        text: siteInfo.phone.text,
        link: siteInfo.phone.link,
    },
    {
        text: 'book an appointment',
        link: '#appointment',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
