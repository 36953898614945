import { PlainBtn, PrimaryBtn } from '@/buttons';
import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { ReactComponent as ChevronIcon } from '@a/icons/navlink-chevron.svg';
import { css } from '@emotion/react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { navigate } from 'gatsby';
import { Fragment, useState } from 'react';
import { NavCallBtn } from './Nav';
import { navLinks, NavLinksTypes, siteInfo } from './NavLinks';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { flexStart } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    background-color: ${theme.colors.offWhite};
    padding: 32px;
    position: fixed;
    top: calc((24px + (1rem * 1.5)) + 64px);
    left: 0;
    right: 0;
    bottom: 0;

    > nav {
        ${flexStart};
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-width: max-content;
    }

    @media (min-height: 720px) {
        padding: 64px;
    }
`;

type MobNavProps = {
    text?: string;
    links?: NavLinksTypes;
};

export const scrollToAppointmentForm = () => {
    const element = document.querySelector('#appointment');
    const y = element && element.getBoundingClientRect().top + window.scrollY - 112;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

export const MobNav = ({ text, links = navLinks }: MobNavProps) => {
    const [open, setOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { title, category } = useLocationData();

    const appointmentScroll = () => {
        setOpen(false);
        setTimeout(
            () =>
                title === 'contact us'
                    ? navigate('/', {
                          state: {
                              scrollToForm: true,
                          },
                      })
                    : scrollToAppointmentForm(),
            100
        );
    };

    return width < 1024 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                {text ? (
                    <PlainBtn
                        css={navBtnStyles}
                        className={category === text ? 'current-nav-category' : ''}
                    >
                        {text} <ChevronIcon />
                    </PlainBtn>
                ) : (
                    <PlainBtn
                        aria-label={open ? 'close navigation menu' : 'open navigation menu'}
                        css={css`
                            @media (min-width: 1024px) {
                                display: none;
                            }
                        `}
                    >
                        {open ? <CloseIcon /> : <MenuIcon />}
                    </PlainBtn>
                )}
            </DialogOpen>

            <DialogContent contentStyles={contentStyles} overlayStyles={overlayStyles}>
                {text && (
                    <DialogClose>
                        <PlainBtn data-mobile-expanded="true" css={navBtnStyles}>
                            {text} <ChevronIcon />
                        </PlainBtn>
                    </DialogClose>
                )}
                <nav>
                    {links.map((data, i) =>
                        data.links ? (
                            <MobNav text={data.text} links={data.links} key={i} />
                        ) : data.text === siteInfo.phone.text ? (
                            <NavCallBtn key={i} />
                        ) : data.text === 'book an appointment' ? (
                            <PrimaryBtn onClick={appointmentScroll} key={i}>
                                {data.text}
                            </PrimaryBtn>
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                </nav>
                <VisuallyHidden.Root>
                    <DialogClose>close this menu</DialogClose>
                </VisuallyHidden.Root>
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
